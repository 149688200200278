<template>
  <div>
    <el-row class="dash-board">
      <el-col v-if="activeName2 == 'first'">
        <p class="in1">培训班名称：</p>
        <el-input style="width: 200px" class="e-inps" v-model="className"></el-input>
        <p class="in2">状态：</p>
        <el-select class="in3" v-model="status">
          <div v-for="(i,k) in statelist" :key="k">
            <el-option :value="i.id" :label="i.value"></el-option>
          </div>
        </el-select>
        <div style="position: absolute;left: 550px; top: 14px;">
          <el-button type="primary" @click="getOne" class="inquire">查询</el-button>
          <el-button type="warning" @click="reset" class="reset">重置</el-button>
        </div>
      </el-col>
      <el-col v-else style="display: flex;align-items: center;padding-left: 15px;">
        <span style="font-size: 13px;"> 培训班名称：</span>
        <el-input v-model="courseQuery.className" style="width: 200px;"></el-input>
        <span style="font-size: 13px;margin-left: 10px;"> 课程内容：</span>
        <el-input v-model="courseQuery.courseName" style="width: 200px;"></el-input>
        <span style="font-size: 13px;margin-left: 10px;"> 状态：</span>
        <el-select v-model="courseQuery.status" style="width: 200px;">
          <el-option v-for="item in statelist" :label="item.value" :value="item.id" :key="item.id"></el-option>
        </el-select>
        <div style="margin-left: 10px;">
          <el-button type="primary" @click="getOne" class="inquire">查询</el-button>
          <el-button type="warning" @click="reset" class="reset">重置</el-button>
        </div>
      </el-col>
    </el-row>
    <el-tabs v-model="activeName2" type="card" @tab-click="handleClick" style="background-color: #FFFFFF;margin-top: 10px;">
      <el-tab-pane label="班级评估" name="first">
        <el-row class="bg">
          <el-table
            :data="tableData"
            style="width: 100%;">
            <el-table-column
              label="培训班名称"
              align="center"
             >
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.className }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="状态"
              align="center"
              >
              <template slot-scope="scope">
                <span v-if="scope.row.status==1">待评估</span>
                <span v-if="scope.row.status==2">已评估</span>
              </template>
            </el-table-column>
            <el-table-column
              label="评估时间"
              align="center"
              >
              <template slot-scope="scope">
                <span v-if="scope.row.status==1">-</span>
                <span v-if="scope.row.status==2">{{scope.row.updateTime}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="info" v-if="scope.row.isStart== 'noStart'">评估未开始</el-button>
                <el-button size="mini" type="info" v-if="scope.row.isAssesclose==2">班级评估已关闭</el-button>
                <el-button size="mini" type="primary" v-if="scope.row.isAssesclose==1 && scope.row.status==1 && scope.row.isStart== 'start'" @click="assessment(scope.row)">评 估</el-button>
                <el-button size="mini" type="success" v-if="scope.row.isAssesclose==1 && scope.row.status==2" @click="see(scope.row.id)">查 看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top:20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total,sizes,  prev, pager, next, jumper"
            :total="total"
            class="pag"
          >
          </el-pagination>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="课程评估" name="second">
        <el-row class="bg">
          <el-table
            :data="courseList"
            style="width: 100%;">
            <el-table-column
              label="班级名称"
              align="center"
             >
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.className }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="课程内容"
              align="center"
             >
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.courseName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="讲师"
              align="center"
             >
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.lecturer }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="状态"
              align="center"
              >
              <template slot-scope="scope">
                <span v-if="scope.row.status==0">待评估</span>
                <span v-if="scope.row.status==1">已评估</span>
              </template>
            </el-table-column>
            <el-table-column
              label="评估等级"
              align="center"
              >
              <template slot-scope="scope">
                <span v-if="scope.row.level">{{ scope.row.level }}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              label="评估时间"
              align="center"
              >
              <template slot-scope="scope">
                <span v-if="scope.row.createTime">{{scope.row.createTime}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              align="center">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" v-if="scope.row.status==0" @click="assessment(scope.row)">评 估</el-button>
                <el-button size="mini" type="success" v-if="scope.row.status==1" @click="see(scope.row.id)">查 看</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row style="margin-top:20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="courseQuery.pageNum"
            :page-size="courseQuery.pageSize"
            layout="total,sizes,  prev, pager, next, jumper"
            :total="courseTotal"
            class="pag"
          >
          </el-pagination>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      width="60%"
      center>
      <el-form :model="form" ref="form" :rules="rules" labelPosition="top">
        <!-- 班级评估 -->
        <div v-if=" activeName2 == 'first'">
          <el-row>
            <el-col :span="8">
               <el-form-item label="给班主任打分" prop="bzrGrade">
                  <el-input style="width: 200px" placeholder="打分0~100分" maxlength="3" name="number" type="text" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="form.bzrGrade"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="给老师打分" prop="teacherGrade">
                <el-input style="width: 200px" placeholder="打分0~100分" maxlength="3" name="number" type="text" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="form.teacherGrade"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="给课程打分" prop="courseGrade">
                <el-input style="width: 200px" placeholder="打分0~100分" maxlength="3" name="number" type="text" onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')" v-model="form.courseGrade"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="课程设计与课程目标" prop="courseTarget">
                <el-select v-model="form.courseTarget" placeholder="请选择评价">
                  <div v-for="(i,k) in teachResultList" :key="k">
                    <el-option :value="i.id" :label="i.value"></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="授课技能与课堂气氛" prop="courseAtmosphere">
                <el-select v-model="form.courseAtmosphere" placeholder="请选择评价">
                  <div v-for="(i,k) in teachResultList" :key="k">
                    <el-option :value="i.id" :label="i.value"></el-option>
                  </div>
                </el-select>
              </el-form-item>
            </el-col>
           <el-col :span="8">
              <el-form-item label="课堂案例中的实用性" prop="coursePracticality">
                <el-select v-model="form.coursePracticality" placeholder="请选择评价">
                  <div v-for="(i,k) in teachResultList" :key="k">
                    <el-option :value="i.id" :label="i.value"></el-option>
                  </div>
                </el-select>
              </el-form-item>
           </el-col>
           <el-col :span="8">
            <el-form-item label="社会实践中的指导性" prop="courseGuiding">
                <el-select v-model="form.courseGuiding" placeholder="请选择评价">
                  <div v-for="(i,k) in teachResultList" :key="k">
                    <el-option :value="i.id" :label="i.value"></el-option>
                  </div>
                </el-select>
              </el-form-item>
           </el-col>
          </el-row>
         <el-row :gutter="20">
           <el-col :span="12">
             <el-form-item label="您对本期班教学环境给予评价">
                <el-input v-model="form.environment" type="textarea" rows="3"></el-input>
              </el-form-item>
          </el-col>
           <el-col :span="12">
              <el-form-item label="您对本期班校内教学课程安排上给予评价和建议">
                <el-input v-model="form.courseArrangement" type="textarea" rows="3"></el-input>
              </el-form-item>
           </el-col>
           <el-col :span="12">
              <el-form-item label="您对本期班校外教学实践上给予评价和建议">
                <el-input v-model="form.teachingPractice" type="textarea" rows="3"></el-input>
              </el-form-item>
           </el-col>
           <el-col :span="12">
            <el-form-item label="您对本期班在教务工作上给予评价和建议">
              <el-input v-model="form.teachingWork" type="textarea" rows="3"></el-input>
            </el-form-item>
           </el-col>
           <el-col :span="12">
              <el-form-item label="您对本期班教学后勤和保障工作上给予评价和建议">
                <el-input v-model="form.teachingLogistics" type="textarea" rows="3"></el-input>
              </el-form-item>
           </el-col>
           <el-col :span="12">
             <el-form-item label="您建议培训课程上需要作哪些补充">
                <el-input v-model="form.courseReplenish" type="textarea" rows="3"></el-input>
              </el-form-item>
           </el-col>
           <el-col :span="12">
             <el-form-item label="您对本期培训的个人心得">
              <el-input v-model="form.mineGrade" type="textarea" rows="3"></el-input>
          </el-form-item>
           </el-col>
         </el-row>
       </div>
       <div v-else>
         <el-row>
           <el-col :span="8">
             <el-form-item label="教学态度" prop="manner">
               <el-select v-model="form.manner" style="width: 200px;">
                 <el-option v-for="i in evalList" :label="i.value" :value="i.value" :key="i.value"></el-option>
               </el-select>
             </el-form-item>
           </el-col>
           <el-col :span="8">
             <el-form-item label="理论深度及信息量" prop="depthInfo">
               <el-select v-model="form.depthInfo" style="width: 200px;">
                 <el-option v-for="i in evalList2" :label="i.value" :value="i.value" :key="i.value"></el-option>
               </el-select>
             </el-form-item>
           </el-col>
           <el-col :span="8">
             <el-form-item label="理论联系实际" prop="actual">
               <el-select v-model="form.actual" style="width: 200px;">
                 <el-option v-for="i in evalList2" :label="i.value" :value="i.value" :key="i.value"></el-option>
               </el-select>
             </el-form-item>
           </el-col>
           <el-col :span="8">
             <el-form-item label="教学技巧及语言表达水平" prop="skillExpress">
               <el-select v-model="form.skillExpress " style="width: 200px;">
                 <el-option v-for="i in evalList2" :label="i.value" :value="i.value" :key="i.value"></el-option>
               </el-select>
             </el-form-item>
           </el-col>
           <el-col :span="8">
             <el-form-item label="教学互动及创新" prop="interactInnovate">
               <el-select v-model="form.interactInnovate" style="width: 200px;">
                 <el-option v-for="i in evalList3" :label="i.value" :value="i.value" :key="i.value"></el-option>
               </el-select>
             </el-form-item>
           </el-col>
           <el-col :span="8">
             <el-form-item label="教学整体效果" prop="effect">
               <el-select v-model="form.effect" style="width: 200px;">
                 <el-option v-for="i in evalList3" :label="i.value" :value="i.value" :key="i.value"></el-option>
               </el-select>
             </el-form-item>
           </el-col>
         </el-row>
       </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="noAuth('form')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="评估详情"
      :data="details"
      :visible.sync="centerDialogVisibleTwo"
       width="70%"
      center>
      <div v-if="activeName2 === 'first'">
        <el-row type="flex" class="row-bg">
          <el-col :span="6">
            <span>给班主任打分: {{details.bzrGrade}}</span>
          </el-col>
          <el-col :span="6">
            <span>给老师打分: {{details.teacherGrade}}</span>
          </el-col>
          <el-col :span="6">
            <span>给课程打分: {{details.courseGrade}}</span>
          </el-col>
          <el-col :span="6">
          </el-col>
         </el-row>
        <el-row type="flex" class="row-bg">
          <el-col :span="6">
            <span>课程设计与课程目标：</span>
            <span v-if="details.courseTarget==1">很好</span>
            <span v-if="details.courseTarget==2">好</span>
            <span v-if="details.courseTarget==3">一般</span>
            <span v-if="details.courseTarget==4">差</span>
            <span v-if="details.courseTarget==5">很差</span>
          </el-col>
          <el-col :span="6"><span>授课技能与课堂气氛：</span>
            <span v-if="details.courseAtmosphere==1">很好</span>
            <span v-if="details.courseAtmosphere==2">好</span>
            <span v-if="details.courseAtmosphere==3">一般</span>
            <span v-if="details.courseAtmosphere==4">差</span>
            <span v-if="details.courseAtmosphere==5">很差</span>
          </el-col>
          <el-col :span="6"><span>课堂案例中的实用性：</span>
            <span v-if="details.coursePracticality==1">很好</span>
            <span v-if="details.coursePracticality==2">好</span>
            <span v-if="details.coursePracticality==3">一般</span>
            <span v-if="details.coursePracticality==4">差</span>
            <span v-if="details.coursePracticality==5">很差</span>
          </el-col>
          <el-col :span="6"><span>社会实践中的指导性：</span>
            <span v-if="details.courseGuiding==1">很好</span>
            <span v-if="details.courseGuiding==2">好</span>
            <span v-if="details.courseGuiding==3">一般</span>
            <span v-if="details.courseGuiding==4">差</span>
            <span v-if="details.courseGuiding==5">很差</span>
          </el-col>
        </el-row>
        <el-row class="row-bg">
         <el-col :span="24">
           <span>对本期班教学环境给予评价：</span>
           <p>{{details.environment}}</p>
         </el-col>
         </el-row>
         <el-row class="row-bg">
         <el-col :span="24">
           <span>对本期班校内教学课程安排上给予评价和建议:</span>
           <p>{{details.courseArrangement}}</p>
         </el-col>
         </el-row>
         <el-row class="row-bg">
         <el-col :span="24">
           <span>对本期班校外教学实践上给予评价和建议：</span>
           <p>{{details.teachingPractice}}</p>
         </el-col>
         </el-row>
         <el-row class="row-bg">
         <el-col :span="24">
           <span>对本期班在教务工作上给予评价和建议：</span>
           <p>{{details.teachingWork}}</p>
         </el-col>
         </el-row>
         <el-row class="row-bg">
         <el-col :span="24">
           <span>对本期班教学后勤和保障工作上给予评价和建议：</span>
           <p>{{details.teachingLogistics}}</p>
         </el-col>
         </el-row>
         <el-row class="row-bg">
         <el-col :span="24">
           <span>建议培训课程上需要作哪些补充 ：</span>
           <p>{{details.courseReplenish}}</p>
         </el-col>
         </el-row>
         <el-row class="row-bg">
         <el-col :span="24">
           <span>对本期培训的个人心得 ：</span>
           <p>{{details.mineGrade}}</p>
         </el-col>
        </el-row>
      </div>
      <div v-else>
        <el-form v-model="details" :inline="true" label-position="top">
          <el-row>
            <el-col :span="8" style="text-align: center;">
              <el-form-item label="教学态度" class="form-item">
                <p class="form-mes" >满分1分（分5档，每档0.2分）</p>
                <p class="form-grade">{{details.manner}} 分</p>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="text-align: center;">
              <el-form-item label="理论深度及信息量" class="form-item" >
                <p class="form-mes">满分2分（分5档，每档0.4分）</p>
                <p class="form-grade">{{details.depthInfo}} 分</p>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="text-align: center;">
              <el-form-item label="理论联系实际" class="form-item" >
                <p class="form-mes">满分2分（分5档，每档0.4分）</p>
                <p class="form-grade">{{details.actual}} 分</p>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="text-align: center;">
              <el-form-item label="教学技巧及语言表达水平" class="form-item" >
                <p class="form-mes">满分2分（分5档，每档0.4分）</p>
                <p class="form-grade">{{details.skillExpress}} 分</p>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="text-align: center;">
              <el-form-item label="教学互动及创新" class="form-item" >
                <p class="form-mes">满分1.5分（分5档，每档0.3分）</p>
                <p class="form-grade">{{details.interactInnovate}} 分</p>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="text-align: center;">
              <el-form-item label="教学整体效果" class="form-item" >
                <p class="form-mes">满分1.5分（分5档，每档0.3分）</p>
                <p class="form-grade">{{details.interactInnovate}} 分</p>
              </el-form-item>
            </el-col>
            <el-col :span="24" style="text-align: center;">
              <el-form-item label="总成绩" class="form-item" >
                <p class="form-mes">满分10分</p>
                <p class="form-grade">{{details.totalPoints}} 分</p>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="centerDialogVisibleTwo = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { assessList, details, assessmentNoAuth, getCourseList, addCourse, getCourseDetail } from '@/api/training.js'
  export default {
    name: 'Cultivate',
    data () {
      return {
        activeName2: 'first',
        id: '',
        classId: '',
        stuId: '',
        className: '',
        status: '',
        title: '填写评估内容',
        statelist: [
          { id: 1, value: '待评估' },
          { id: 2, value: '已评估' }
        ],
        // 评估--教学态度
        evalList: [
          { value: 0.2, lable: '一档' },
          { value: 0.4, lable: '二档' },
          { value: 0.6, lable: '三档' },
          { value: 0.8, lable: '四档' },
          { value: 1, lable: '五档' }
        ],
        // 评估--理论深度及信息量--理论联系实际--教学技巧及语言表达水平
        evalList2: [
          { value: 0.4, lable: '一档' },
          { value: 0.8, lable: '二档' },
          { value: 1.2, lable: '三档' },
          { value: 1.6, lable: '四档' },
          { value: 2, lable: '五档' }
        ],
        // 评估--教学互动及创新--教学整体效果
        evalList3: [
          { value: 0.3, lable: '一档' },
          { value: 0.6, lable: '二档' },
          { value: 0.9, lable: '三档' },
          { value: 1.2, lable: '四档' },
          { value: 1.5, lable: '五档' }
        ],
        tableData: [],
        courseList: [],
        // tableData: {
        //   id: '',
        //   className: '',
        //   status: ''
        // },
        details: {},
        courseDetails: [],
        remark: '',
        centerDialogVisible: false,
        centerDialogVisibleTwo: false,
        form: {
          id: '',
          courseTarget: '',
          courseAtmosphere: '',
          coursePracticality: '',
          courseGuiding: '',
          // teachTarget: '',
          // teachSuggest: '',
          bzrGrade: '',
          teacherGrade: '',
          courseGrade: '',
          mineGrade: '',
          environment: '',
          courseArrangement: '',
          teachingPractice: '',
          teachingWork: '',
          teachingLogistics: '',
          courseReplenish: ''
        },
        teachResultList: [
          { id: 1, value: '很好' },
          { id: 2, value: '好' },
          { id: 3, value: '一般' },
          { id: 4, value: '差' },
          { id: 5, value: '很差' }
        ],
        total: 0,
        pageNum: 1,
        pageSize: 10,
        courseTotal: 0,
        courseQuery: {
          pageNum: 1,
          pageSize: 10,
          classId: null,
          className: null,
          courseId: null,
          courseName: null,
          lecturer: null,
          status: null,
          createTime: null
        },
        rules: {
          bzrGrade: [{ required: true, message: '给班主任打分', trigger: 'blur' }],
          teacherGrade: [{ required: true, message: '给老师打分', trigger: 'blur' }],
          courseGrade: [{ required: true, message: '给课程打分', trigger: 'blur' }],
          courseTarget: [{ required: true, message: '课程设计与课程目标', trigger: 'change' }],
          courseAtmosphere: [{ required: true, message: '授课技能与课堂气氛', trigger: 'change' }],
          coursePracticality: [{ required: true, message: '课堂案例中的实用性', trigger: 'change' }],
          courseGuiding: [{ required: true, message: '社会实践中的指导性', trigger: 'change' }],
          manner: [{ required: true, message: '教学态度不能为空', trigger: 'change' }],
          depthInfo: [{ required: true, message: '理论深度及信息量不能为空', trigger: 'change' }],
          actual: [{ required: true, message: '理论联系实际不能为空', trigger: 'change' }],
          skillExpress: [{ required: true, message: '教学技巧及语言表达水平不能为空', trigger: 'change' }],
          interactInnovate: [{ required: true, message: '教学互动及创新不能为空', trigger: 'change' }],
          effect: [{ required: true, message: '教学整体效果不能为空', trigger: 'change' }]
          // teachTarget: [{ required: true, message: '教学目标', trigger: 'change' }],
        }
      }
    },
    mounted () {
      // 钩子函数
      this.getOne()
      // this.details()
    },
    methods: {
      handleClick(e) {
        this.getCourseList()
      },
      getOne () {
        this.getCourseList()
        this.getAssessList()
      },
      getAssessList() {
        const query = {
          className: this.className,
          status: this.status,
          userName: this.$store.getters.username,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        assessList(query).then(resp => {
          if (resp.data.code === '0') {
            this.tableData = resp.data.data.rows //  接收返回的数据
            this.total = resp.data.data.total // 查询总条数
          }
        })
      },
      getCourseList() {
        getCourseList(this.courseQuery).then(res => {
          console.log(res)
          this.courseList = res.data.data.rows
          this.courseTotal = res.data.data.total
        })
      },
      reset() {
        this.className = ''
        this.status = ''
        // this.courseQuery = {
        //   pageNum: 1,
        //   pageSize: 10,
        //   classId: null,
        //   className: null,
        //   courseId: null,
        //   courseName: null,
        //   lecturer: null,
        //   status: null,
        //   createTime: null
        // },
        this.courseQuery = {
          pageNum: 1,
          pageSize: 10
        }
        this.getCourseList()
      },
      assessment (row) {
        this.centerDialogVisible = true
        this.id = row.id
        this.stuId = row.stuId
        this.classId = row.classId
        this.form.courseId = row.courseId
      },
      noAuth (form) {
        this.$refs[form].validate(valid => {
          if (valid) {
            const assess = {
              id: this.id,
              classId: this.classId,
              stuId: this.stuId,
              courseTarget: this.form.courseTarget,
              courseAtmosphere: this.form.courseAtmosphere,
              coursePracticality: this.form.coursePracticality,
              courseGuiding: this.form.courseGuiding,
              // teachTarget: this.form.teachTarget,
              environment: this.form.environment,
              courseArrangement: this.form.courseArrangement,
              teachingPractice: this.form.teachingPractice,
              teachingWork: this.form.teachingWork,
              teachingLogistics: this.form.teachingLogistics,
              courseReplenish: this.form.courseReplenish,
              mineGrade: this.form.mineGrade,
              bzrGrade: this.form.bzrGrade,
              teacherGrade: this.form.teacherGrade,
              courseGrade: this.form.courseGrade
            }
            if (this.activeName2 === 'first') {
              assessmentNoAuth(assess).then(resp => {
                this[form] = {}
                if (resp.data.code === '0') {
                  this.centerDialogVisible = false
                  this.$notify({
                    title: '评估成功',
                    type: 'success'
                  })
                  this.centerDialogVisible = false
                  this.getOne()
                }
              })
            } else {
              addCourse(this.form).then(res => {
                this[form] = {}
                if (res.data.code === '0') {
                  this.centerDialogVisible = false
                  this.$notify({
                    title: '评估成功',
                    type: 'success'
                  })
                  this.centerDialogVisible = false
                  this.getCourseList()
                }
              })
            }
          }
        })
      },
      see (id) {
        this.centerDialogVisibleTwo = true
        this.id = id
        if (this.activeName2 === 'first') {
          details(this.id).then(resp => {
            this.details = resp.data.data
          })
        } else {
          //  查看课程评估详情
          // let data = []
          getCourseDetail(this.id).then(res => {
           this.details = res.data.data
            // console.log(data)
            // this.courseDetails[0] = res.data.data
            // console.log(this.courseDetails)
          })
        }
      },
      handleSizeChange(e) {
        this.pageSize = e
        this.courseQuery.pageSize = e
        this.getOne()
        this.getCourseList()
      },
      handleCurrentChange(e) {
        this.pageNum = e
        this.courseQuery.pageNum = e
        this.getOne()
        this.getCourseList()
      }
    }
  }
</script>

<style scoped>
  .dash-board {
    display: flex;
    background-color: white;
    height: 60px;
    padding: 10px 0;
  }
  .bg{margin-top: 0; padding:0 20px; background-color: white;}
  .in1{font-size: 13px; position: absolute; top: 10px; left: 17px}
  .in2{font-size: 13px; position: absolute; top:10px; left: 330px}
  .in3{width: 160px; position: absolute; top: 14px;left: 370px}
  .spani{color: #2c2fd6}
  .e-inps{position: absolute; left: 100px; top: 14px}
  .row-bg{ padding: 10px 0px;}
  .row-text{line-height: 24px;}
  .form-mes{line-height: 12px; margin: 0; padding: 0 10px; font-size: 12px;font-weight: 100;color:#a66262}
  .form-grade{background-color: #FFFFFF;margin-bottom: 0;}
  .form-item{ width: 100%; background-color: #F5F7FA;border: 1px solid #F2F2F2;}
</style>
